import React from "react"
import Layout from "@components/layout";
import FormsParts from "@components/forms-contactform7";

// 関数呼び出し
import { SeoContents } from "@components/seo-data.js" // Seo情報設定

const Contact = () => {

  // Seo情報設定
  const pathName = 'contact';
  const seoTag = SeoContents({ Path:pathName });
 
  return (
  <>
    <Layout>
      {seoTag}
      <div> 
        {/* FV */}
        <div className="corp_h1">
          <h1 className="font-semibold ">お問い合わせ</h1>
        </div>
        <div className="md:max-w-2xl  mx-auto px-6 pt-5  md:pt-12"> 
        <div className=" text-left mb-[50px] max-w-xs md:max-w-5xl md:mx-auto mx-6 md:mt-[30px] mt-[50px]"> 
            <h2 className="pt-2 font-semibold tracking-wide text-2xl md:text-3xl ">Contact</h2>
          </div>
          <div className="mb-[100px]">
            <FormsParts/>
          </div>
        </div>
      </div>
    </Layout> 
  </>
  )
}
export default Contact
